






















import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

const DEFAULT_TEXT = 'Infelizmente seu tempo acabou, pressione o botão de finalizar para prosseguir.';

@Component({
  components: {
    Modal
  }
})
export default class ModalTimeFinish extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ default: DEFAULT_TEXT }) text!: string;

  handleClickButton() {
    this.$emit('click-button');
  }
}
