import { render, staticRenderFns } from "./SendRedactionLoading.vue?vue&type=template&id=3b79d3a6&scoped=true&"
import script from "./SendRedactionLoading.vue?vue&type=script&lang=ts&"
export * from "./SendRedactionLoading.vue?vue&type=script&lang=ts&"
import style0 from "./SendRedactionLoading.scss?vue&type=style&index=0&id=3b79d3a6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b79d3a6",
  null
  
)

export default component.exports