










































import { Component, Vue } from 'vue-property-decorator';
import { throttle } from 'lodash';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import ContainerSendRedaction from '@/components/Redaction/ContainerSendRedaction/ContainerSendRedaction.vue';
import ButtonMovie from '@/components/ButtonMovie/ButtonMovie.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ModalSuccess from '@/components/Redaction/Modals/ModalSuccess.vue';
import ModalError from '@/components/Modal/ModalError/ModalError.vue';
import ModalTimeFinish from '@/components/Modal/ModalTimeFinish/index.vue';

import SendRedactionLoading from './SendRedactionLoading.vue';
import HeaderSendRedaction from './components/HeaderSendRedaction/index.vue';

import ActiveModal from '@/share/Util/ActiveModal';
import { UrlTreatmentSaveVersa } from '@/share/Util/Image/Image';

import RedactionService from '@/services/Redaction/RedactionService';

interface DataRedaction {
  type: string;
  content: string;
}

const TEXT_MODAL_ERRO = 'Algo deu errado! Não foi possível realizar o envio da sua redação. Tente novamente.';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    ContainerFluid,
    ContainerSendRedaction,
    SendRedactionLoading,
    FeedbackUser,
    ModalSuccess,
    ModalError,
    ModalTimeFinish,
    ButtonMovie,
    Footer,
    HeaderSendRedaction
  }
})
export default class SendRedaction extends Vue {
  private redactionTitle = '';
  private textModalErro = TEXT_MODAL_ERRO;
  private isLoading = true;
  private isLoadingButton = false;
  private timesUpAndSendRedaction = false;
  private stopTime = false;

  private setModal = new ActiveModal();
  private RedactionService = new RedactionService();
  private sendRedactionUnique = throttle(
    (data: DataRedaction) => {
      this.sendRedaction({
        type: data.type,
        content: data.content
      });
    },
    3000,
    { trailing: false }
  );

  created() {
    this.getRedactionTitle();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  beforeDestroy() {
    this.$store.commit('redactionSubmissionSettings', {
      component: '',
      time: null
    });
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get currentTimeRedaction() {
    return this.$store.getters.currentTimeRedaction;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  async getRedactionTitle() {
    try {
      this.isLoading = true;
      const { themeId } = this.$route.params;

      const response = await this.RedactionService.getRedactionDescription(Number(themeId));
      this.redactionTitle = response.title;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar tema de envio da redação.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async sendRedaction({ type, content }: DataRedaction) {
    if (this.isLoadingButton) return;

    try {
      if (!this.timesUpAndSendRedaction) this.stopTime = true;

      this.isLoadingButton = true;
      const { themeId } = this.$route.params;
      let newContent = content;

      if (type === 'URL') {
        newContent = UrlTreatmentSaveVersa(content);
      }

      await this.RedactionService.saveRedaction({
        themeID: Number(themeId),
        [type]: newContent,
        time: this.currentTimeRedaction
      });

      this.updateStoreTotalRedactionSendForMonth();
      this.showModalSuccess();
    } catch (error) {
      this.showModalError();
      console.error(error);
    } finally {
      this.isLoadingButton = false;
    }
  }

  updateStoreTotalRedactionSendForMonth() {
    const totalSendForMonth = Number(this.$store.getters.totalRedactionsMeMonthly);

    this.$store.commit('setTotalRedactionsMeMonthly', totalSendForMonth + 1);
  }

  goBack() {
    const { themeId } = this.$route.params;

    this.$router.push({
      name: 'RedactionInstruction',
      params: {
        themeId
      }
    });
  }

  showModalError() {
    this.setModal.activeModal('modalError');
  }

  showModalSuccess() {
    this.setModal.activeModal('modalRedactionConfirmed');
  }

  showModalTimeFinish() {
    this.setModal.activeModal('ModalTimeFinish');
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Redação ', to: '/redacao' },
      { title: 'Envio', to: null }
    ]);
  }
}
