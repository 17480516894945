












import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import moment from 'moment';

@Component
export default class HeaderSendRedaction extends Vue {
  @Prop() redactionTitle!: string;
  @Prop() stopTime!: boolean;

  private secondsCounter = 0;
  private clock = '';

  mounted() {
    this.setStartTimeCount();
  }

  get timeInSeconds() {
    return Number(
      this.$store.getters.redactionSubmissionSettings.time
        || sessionStorage.getItem('timeSendRedaction')
    );
  }

  setStartTimeCount() {
    if (this.timeInSeconds) {
      this.secondsCounter = this.timeInSeconds;
    }
  }

  @Watch('stopTime')
  @Watch('secondsCounter')
  timeCounter() {
    if (this.secondsCounter === -1 && this.timeInSeconds) {
      this.$emit('times-up');
      return;
    }

    if (!this.stopTime) {
      setTimeout(() => {
        this.secondsCounter -= 1;
      }, 1000);

      const formattedTime = moment.utc(this.secondsCounter * 1000).format('HH:mm:ss');
      this.clock = formattedTime;
    }

    this.$store.commit('setCurrentTimeRedaction', this.secondsCounter);
  }
}
